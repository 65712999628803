import { DoubleCheckModalText } from './../../../shared/player.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { UserInfoModalComponent } from 'src/app/modals/user-info-modal/user-info-modal.component';
import { PlayerAction, PlayerStatus, PlayerStatusNamed } from 'src/app/shared/player.enum';
import { Criteria, Pageable, Player } from '../player.model';
import { PlayerService } from '../player.service';
import { DoubleCheckModalComponent } from './../../../modals/double-check-modal/double-check-modal.component';
import { DashboardData, GameAccounts } from './../player.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';


@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit, OnDestroy {
  @ViewChild('doubleCheckModal', { static: true }) public doubleCheckModal!: DoubleCheckModalComponent;
  @ViewChild('userInfoModal', { static: true }) public userInfoModal!: UserInfoModalComponent;

  subscription: Subject<void> = new Subject();
  searchChanged: Subject<Criteria> = new Subject<Criteria>();

  criteria: Criteria = new Criteria();
  players: Player[] = [];
  filterByType: PlayerStatus = this.playerStatus.ALL;
  openModal = false;
  playerId!: string;
  gameAccountData: GameAccounts = {} as GameAccounts;
  activeTab = 1;
  isInviteNewPlayersPage!: boolean;

  constructor(
    public service: PlayerService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.searchChanged.pipe(debounceTime(700))
      .subscribe(searchCriteria => this.updatePlayerList(searchCriteria));
  }

  ngOnInit(): void {
    this.getDashboardData()
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.searchAcc) {
        this.criteria.searchTerm = params.searchAcc;
      }
      this.updatePlayerList(this.criteria);
    });
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.unsubscribe();
  }

  onSearchChange(criteria: Criteria): void {
    this.searchChanged.next(criteria);
  }

  onTabChange(event: any): void {
    const tabId = event.nextId;
    if (tabId === 1) {
      this.criteria.tab = 'all';
    }
    if (tabId === 2) {
      this.criteria.tab = 'custom';
    }
    if (tabId === 3) {
      this.criteria.tab = 'invited';
    }
    this.criteria.page = 1;
    this.updatePlayerList(this.criteria);
  }

  updatePlayerList(criteria: Criteria): any {
    if (!criteria.searchTerm) {
      this.spinner.show();
    }
    this.criteria.page = criteria.page;
    this.service.listPlayers(criteria)
      .pipe(
        takeUntil(this.subscription.asObservable()),
        finalize(() => {
          this.spinner.hide();
        }))
      .subscribe((response: Pageable<Player>) => {
        this.players = response.data;
        this.criteria.totalDocs = response.totalDocs || response.total;
        this.criteria.page = response.page;
        this.criteria.limit = response.limit;
        this.criteria.totalPages = response.totalPages || response.pages;
        if (this.criteria.tab === 'invited') {
          this.onUpdateInviteNewPlayers();
        } else {
          this.isInviteNewPlayersPage = false;
        }
      });
  }

  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.updatePlayerList(this.criteria);
  }

  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  public get doubleCheckModalText(): typeof DoubleCheckModalText {
    return DoubleCheckModalText;
  }

  onFilterChange(event: PlayerStatus): void {
    this.criteria.listByStatus = event;
    this.updatePlayerList(this.criteria);
  }

  onDoubleCheckConfirmed(): void {
    this.getDashboardData();
    this.updatePlayerList(this.criteria);
  }

  openDoubleCheckModal(player: Player, type: string): void {
    switch (type) {
      case this.playerAction.ACTIVATE:
        this.doubleCheckModal.titleText = "Realmente deseja ativar a conta desse jogador?";
        this.doubleCheckModal.buttonText = "Ativar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.ACTIVATE_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.ACTIVATE);
        break;
      case this.playerAction.RESET_ATTEMPS:
        this.doubleCheckModal.titleText = "Realmente deseja resetar as tentativas desse jogador?";
        this.doubleCheckModal.buttonText = "Resetar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.RESET_ATTEMPS_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.RESET_ATTEMPS);
        break;
      case this.playerAction.RESET_WITHDRAW:
        this.doubleCheckModal.titleText = "Resetar quantidades de saque do jogador";
        this.doubleCheckModal.buttonText = "Resetar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.RESET_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.RESET_WITHDRAW);
        break;
      case this.playerAction.BLOCK_WITHDRAW:
        this.doubleCheckModal.titleText = "Realmente deseja bloquear o saque desse jogador?";
        this.doubleCheckModal.buttonText = "Bloquear saque";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.BLOCK_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.BLOCK_WITHDRAW);
        break;
      case this.playerAction.UNBLOCK_WITHDRAW:
        this.doubleCheckModal.titleText = "Realmente deseja desbloquear o saque desse jogador?";
        this.doubleCheckModal.buttonText = "Desbloquear saque";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.UNBLOCK_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.UNBLOCK_WITHDRAW);
        break;
      case this.playerAction.BLOCK:
        this.doubleCheckModal.titleText = "Realmente deseja bloquear a conta desse jogador?";
        this.doubleCheckModal.buttonText = "Bloquear";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.BLOCK_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.BLOCK);
        break;
      case this.playerAction.WITHDRAW_MANUAL:
        this.doubleCheckModal.titleText = "Realmente deseja alterar o tipo de saque desse jogador para manual?";
        this.doubleCheckModal.buttonText = "Alterar para saque manual";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.WITHDRAW_MANUAL_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.WITHDRAW_MANUAL);
        break;
      case this.playerAction.WITHDRAW_AUTOMATIC:
        this.doubleCheckModal.titleText = "Realmente deseja alterar o tipo de saque desse jogador para automático?";
        this.doubleCheckModal.buttonText = "Alterar para saque automático";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.WITHDRAW_AUTOMATIC_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.WITHDRAW_AUTOMATIC);
        break;
      case this.playerAction.CUSTOMIZE_WITHDRAW:
        this.doubleCheckModal.titleText = "Realmente deseja personalizar o saque desta conta?";
        this.doubleCheckModal.buttonText = "Personalizar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.CUSTOMIZE_WITHDRAW;
        this.doubleCheckModal.openModal(player, this.playerAction.CUSTOMIZE_WITHDRAW);
        break;
      default:
        break;
    }
  }

  openUserInfoModal(player: Player): void {
    this.userInfoModal.openModal(player);
  }

  getDashboardData(): void {
    this.service.getDashboardData().subscribe(
      (response: DashboardData) => {
        this.gameAccountData = response.data.gameAccounts;
      }
    )
  }

  onUpdateInviteNewPlayers(): void {
    this.isInviteNewPlayersPage = true;
  }
}

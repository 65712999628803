<nav id="sidebarMenu" class="border-right sidebar collapse bg-white position-fixed light-blue-backdrop shadow-sm"
   [ngClass]="{'show': !isMobileScreen && (!isSmallScreen || isSidebarVisible)}">
  <div class="sidebar-sticky py-2 px-2 min-vh-100 light-blue-backdrop">
    <ul ngbNav #nav="ngbNav" [activeId]="route.fragment | async" class="nav nav-pills nav-stacked"
      orientation="vertical">
      <li ngbNavItem="transaction">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="transaction"  [routerLink]="['transaction']" (click)="hideNavBar()">
          <i class="bi bi-arrow-left-right mr-3" style="font-size: 1.2rem;"></i>Transações
        </a>
      </li>
      <li ngbNavItem="player">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="player"  [routerLink]="['player']" (click)="hideNavBar()">
          <i class="bi bi-people-fill mr-3" style="font-size: 1.2rem;"></i>Meus jogadores
        </a>
      </li>
      <li ngbNavItem="finance">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="finance"  [routerLink]="['finance']" (click)="hideNavBar()">
          <i class="bi bi-cash mr-3" style="font-size: 1.2rem;"></i>Movimentação
        </a>
      </li>
      <li ngbNavItem="dashboard">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="dashboard" [routerLink]="['dashboard']"(click)="hideNavBar()">
          <i class="bi bi-graph-up mr-3" style="font-size: 1.2rem;"></i>Gráfico
        </a>
      </li>
      <li ngbNavItem="control">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="control" [routerLink]="['control']" (click)="hideNavBar()">
          <i class="bi bi-sliders mr-3" style="font-size: 1.2rem;"></i>Controle
        </a>
      </li>
      <li ngbNavItem="help">
        <a ngbNavLink class="p-2 d-flex align-items-center" fragment="help"  [routerLink]="['help']" (click)="hideNavBar()">
          <i class="bi bi-question-circle-fill mr-3" style="font-size: 1.2rem;" ></i>Ajuda
        </a>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="ml-4"></div>
    <!-- <pre>Active: {{ active }}</pre> -->
  </div>
</nav>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { TransactionService } from "src/app/pages/transactions/transaction.service";
import { CashoutReceipt } from "src/app/shared/cashout-receipt.model";
import { OperationPurpose, OperationPurposeNamed, OperationStatusNamed, OperationType, OperationTypeNamed } from "src/app/shared/finance-account-type.enum";

@Component({
	selector: 'app-finance-slide-panel',
	templateUrl: './finance-slide-panel.component.html',
	styleUrls: ['./finance-slide-panel.component.scss']
})
export class FinanceSlidePanelComponent implements OnInit, OnChanges {

	@Input('isOpen') isOpen: boolean = false;
	@Input('operation') operation: any;

	@Output() eventClose: EventEmitter<any> = new EventEmitter();
	@Output() actionConfirmed = new EventEmitter();

	isSkeletonLoading: boolean = false;
	mustShowTransactionDetails = false;
	transactionID!:any;
	prevOperationId: string | null = null;

	constructor(
		public transactionService: TransactionService,
		private router: Router
	) { }

	public get operationTypeNamed(): any {
		return OperationTypeNamed;
	}

	public get operationPurposeNamed(): any {
		return OperationPurposeNamed;
	}

	public get operationStatusNamed(): any {
		return OperationStatusNamed;
	}

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['operation']) {
			const prevOperation = changes['operation'].previousValue; 
			const currentOperation = changes['operation'].currentValue;

			if (prevOperation?._id !== currentOperation?._id) {
			this.prevOperationId = currentOperation?._id; 
			this.mustShowTransactionDetails = false;
			} else {
			this.mustShowTransactionDetails = true;
			}
		}           
	}

	onBack() {
		if (this.mustShowTransactionDetails) {
		  this.mustShowTransactionDetails = false
		} else {
		  this.isOpen = false;
		  this.eventClose.emit();
		}
	  }

	convertAmountToString(amount: number): string {
		const amountValue = amount.toString().length === 2 ? `0${amount}` : amount;
		return amountValue === 0
			? 'R$ 0.00'
			: `R$ ${amountValue.toString().slice(0, -2)},${amountValue
				.toString()
				.slice(-2)}`;
	}

	checkValueForLiquid(operation: any) {
		const amount = operation.amount / 100;
		if (operation.type === OperationType.CASH_IN) {
			return amount - 0.5;
		}
		return amount;
	}

	checkValueForGross(operation: any) {
		const amount = operation.amount / 100;
		if (operation.type === OperationType.CASH_OUT) {
			if (operation.purpose === OperationPurpose.WITHDRAW_BALANCE) {
				return amount + 1.5;
			} else if (operation.purpose === OperationPurpose.CLAIMBACK_PAYMENT) {
				return amount + 0.5;
			}
		}
		return amount;
	}

	onGenereteCashoutReceipt(pixId: string) {
		this.transactionService
			.getCashoutReceipt(pixId)
			.subscribe((cashoutReceipt: Blob) => {
				const fileURL = URL.createObjectURL(cashoutReceipt);

				window.open(fileURL, '_blank');

				const downloadAnchor = document.createElement('a');
				downloadAnchor.href = fileURL;
				downloadAnchor.download = 'Comprovante.pdf';

				document.body.appendChild(downloadAnchor);
				downloadAnchor.click();
				document.body.removeChild(downloadAnchor);

				URL.revokeObjectURL(fileURL);
			});
	}

	openDetailsInTransactions(transactionID: string) {
		console.log(transactionID)
		this.mustShowTransactionDetails = true;
		this.transactionID = transactionID
  }

  goToTransactionsList() {
	let fragment = 'transaction';
	this.router.navigate(['/transaction'], { fragment } );
  }
}
<app-finance-withdraw-modal #financeWithdrawModal (confirm)="onConfirmModal()"></app-finance-withdraw-modal>
<app-finance-add-funds-modal #financeAddFundsModal (deny)="onConfirmModal()"></app-finance-add-funds-modal>
<app-operation-modal #operationModal (deny)="onConfirmModal()"></app-operation-modal>

<div class="contents-wrapper">
  <div class="main-content" [class.shifted]="isSlidePanelOpen">
    <div class="h4 font-weight-bold">Movimentação</div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-sm bg-white mb-4 mr-sm-4 mb-sm-0 p-3 border-left border-success"
          style="border-width: 7px !important;">
          <dt class="text-muted">Disponível</dt>
          <div class="item mt-2" *ngIf="balanceSkeletonLoading">
            <ngx-skeleton-loader [theme]="{'height': '48px', 'width': '50%'}" count="1" appearance="line"
              animation="progress">
            </ngx-skeleton-loader>
          </div>
          <div class="h2" *ngIf="!balanceSkeletonLoading">
            {{ balance.amount/100 | currency: " " }}
          </div>
        </div>
      </div>
    </div>

    <div class="finance-actions-wrapper form-inline mt-5 d-flex justify-content-sm-between bg-light">
      <div class="input-group">
        <input [(ngModel)]="amount" type="tel" name="price" id="price" class="form-control"
          name="currency" currencyMask style="width: 150px;" />
        <div class="input-group-append">
          <button [disabled]="!amount" class="btn btn-primary" type="button" id="button-addon2"
            (click)="openFinanceAddFundsModal()">Adicionar</button>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary" [disabled]="!financeAccountCreated"
          (click)="openFinanceWithdrawModal()">
          Efetuar Resgate
        </button>
      </div>
    </div>

    <div class="bg-white">
      <div class="bg-light mt-4">
        <div class="view-tabs-wrapper">
          <div class="text-muted">Visualizar movimentações por:</div>
          <div class="btn-group" role="group" aria-label="radio toggle button group">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-filter active m-0">
                <input type="radio" name="options" id="1" autocomplete="off" (change)="onTabChange(1)" checked>Operações
              </label>
              <label class="btn btn-filter m-0">
                <input type="radio" name="options" id="2" autocomplete="off" (change)="onTabChange(2)">Dados para
                resgate
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeTab === 1">
        <div class="form-inline m-0 pt-4 d-flex justify-content-end bg-light">
          <button type="button" class="btn btn-light border d-flex align-items-center text-primary mr-3"
            (click)="exportOperations()">
            <i class="bi bi-file-earmark-arrow-down mr-2"></i>
            Exportar
          </button>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn btn-light border" id="listDropdown" ngbDropdownToggle>{{selectedPeriod}}</button>
            <div ngbDropdownMenu aria-labelledby="lostDropdow">
              <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.LAST_WEEK)">Últimos 7 dias</button>
              <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.TODAY)">Hoje</button>
              <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.ALL_PERIOD)">Todo o período</button>
              <div class="dropdown-divider"></div>
              <form class="p-2">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-white" id="datePickerDropdown" ngbDropdownToggle>Dia específico</button>
                  <div ngbDropdownMenu aria-labelledby="datePickerDropdown" class="p-0">
                    <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"
                      (dateSelect)="onSpecificDaySelected($event)"></ngb-datepicker>
                  </div>
                </div>
              </form>
              <form class="p-2">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-white" id="datePickerDropdown" ngbDropdownToggle>Período
                    personalizado</button>
                  <div ngbDropdownMenu aria-labelledby="datePickerDropdown" class="p-0">
                    <form class="">
                      <ngb-datepicker #dp (dateSelect)="onRangeDateSelect($event)" [displayMonths]="1" [dayTemplate]="t"
                        outsideDays="hidden">
                      </ngb-datepicker>
                      <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </form>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          <div class="total-cards border border-0">
            <div class="row row-cols-md-2 align-items-center justify-content-between bg-light rounded">
              <div class="col-md-6 mb-4 mt-4 cashin-card">
                <div class="p-4 rounded-lg bg-white shadow-sm d-flex align-items-center"
                  style="height: 110px;">
                  <i class="bi bi-arrow-down-right mr-4" style="font-size: 1.5rem;"></i>
                  <div class="">
                    <dt class="text-muted">Total de entradas</dt>
                    <div class="item mt-2" *ngIf="operationFlowSkeletonLoading">
                      <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '100px'}" count="1" appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="h5" *ngIf="!operationFlowSkeletonLoading">
                      {{ operationFlow.cashIn/100 | currency: 'R$' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4 mt-4 cashout-card">
                <div class="p-4 rounded-lg bg-white shadow-sm d-flex align-items-center"
                  style="height: 110px;">
                  <i class="bi bi-arrow-up-right mr-4" style="font-size: 1.5rem;"></i>
                  <div class="">
                    <dt class="text-muted">Total de saídas</dt>
                    <div class="item mt-2" *ngIf="operationFlowSkeletonLoading">
                      <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '100px'}" count="1" appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="h5" *ngIf="!operationFlowSkeletonLoading">
                      {{ operationFlow.cashOut/100 | currency: 'R$' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive table-striped table-hover " *ngIf="!operationSkeletonLoading">
          <table class="table text-nowrap table-borderless m-0">
            <thead class="border-top border-bottom border-light">
              <tr class="text-muted">
                <th scope="col" class="text-center">ID Jogador</th>
                <th scope="col" class="text-center">Data</th>
                <th scope="col" class="text-center">Hora</th>
                <th scope="col" class="text-center">Valor</th>
                <th scope="col" class="text-center">Tipo</th>
                <th scope="col" class="text-center">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let operation of operations" class="border-top border-bottom align-middle"
                style="height: 70px;" [ngClass]="{'selected' : operation === selectedOperation}">
                <td class="align-middle text-center">
                  <ngb-highlight [result]="operation.playerId" *ngIf="operation.playerId">
                  </ngb-highlight>
                  <ngb-highlight [result]="'---'" *ngIf="!operation.playerId">
                  </ngb-highlight>
                </td>
                <td class="align-middle text-center">
                  <ngb-highlight [result]="operation.createdAt | date: 'd/M/yy'">
                  </ngb-highlight>
                </td>
                <td class="align-middle text-center">
                  <ngb-highlight [result]="operation.createdAt | date: 'HH:mm'">
                  </ngb-highlight>
                </td>
                <td class="align-middle text-center" *ngIf="operation.type === operationType.CASH_IN">
                  <ngb-highlight [result]="operation.amount/100 | currency:'BRL':'symbol':'1.2-2'">
                  </ngb-highlight>
                </td>
                <td class="align-middle text-center" *ngIf="operation.type === operationType.CASH_OUT">
                  <ngb-highlight [result]="checkValueForGross(operation) | currency:'BRL':'symbol':'1.2-2'">
                  </ngb-highlight>
                </td>
                <td [ngSwitch]="operation.type" class="text-center font-weight-light align-middle">
                  <span class="badge badge-pill badge-info font-weight-normal"
                    *ngSwitchCase='operationType.CASH_IN'>{{operationTypeNamed[operationType.CASH_IN]}}</span>
                  <span class="badge badge-pill badge-danger text-wrap font-weight-normal"
                    *ngSwitchCase='operationType.CASH_OUT'>{{operationTypeNamed[operationType.CASH_OUT]}}</span>
                </td>
                <td [ngSwitch]="operation.action" class="text-center font-weight-light align-middle">
                  <button *ngSwitchDefault type="button" class="nav-btn btn btn-light btn-sm border"
                    (click)="openFinanceSlidePanel(operation)" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <span class="material-symbols-outlined">chevron_right</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive" *ngIf="operationSkeletonLoading">
          <table class="table text-nowrap table-borderless m-0">
            <thead class="border-top border-bottom border-light">
              <tr class="text-muted">
                <th scope="col" class="text-center">Data</th>
                <th scope="col" class="text-center">Hora</th>
                <th scope="col" class="text-center">Valor</th>
                <th scope="col" class="text-center">Tipo</th>
                <th scope="col" class="text-center">Ação</th>
              </tr>
            </thead>
          </table>
          <div class="item bg-light">
            <ngx-skeleton-loader [theme]="{'height.px': 70}" count="14" appearance="line" animation="progress">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div *ngIf="this.operations.length === 0">
          <div class="alert alert-light text-center mx-auto mb-0" role="alert">
            Ainda não houve operações até o momento.
          </div>
        </div>
        <div *ngIf="this.operations.length > 0" class="row d-flex justify-content-between table-responsive m-0">
          <p class="mt-4 mx-3">
            <small class="font-weight-light">
              Exibindo
              <span class="font-weight-normal">{{criteria.page}}</span>
              de
              <span class="font-weight-normal">{{criteria.pages}}</span>
              {{criteria.pages > 1 ? 'páginas': 'página'}}
            </small>
          </p>
          <ngb-pagination
            class="mt-3 mx-3"
            [collectionSize]="criteria.total"
            [(page)]="criteria.page"
            [pageSize]="criteria.limit"
            [maxSize]="maxSize"
            [rotate]="rotatePagination"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="jumpPagination($event)"
            [hidden]="criteria.page > criteria.total">
          </ngb-pagination>
        </div>
      </div>
      <div *ngIf="activeTab === 2">
        <div class="container-fluid bg-light">
          <div class="row row-cols-md-2 justify-content-between">
            <div class="col-md-12 bg-light mt-4 p-0" *ngIf="financeAccountCreated === false">
              <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
                <i class="bi bi-question-circle-fill mr-3"></i>
                <small>Preencha os <strong>dados</strong> para poder receber as transações e efetuar
                  resgates.</small>
              </div>
            </div>
            <div class="col-md-4 mt-5 p-0">
              <h5>Detalhes</h5>
            </div>
            <div class="col-md-7 mt-5 p-4 rounded-lg bg-white">
              <label class="text-muted">Nome completo</label>
              <p>{{ finance?.fullName }}</p>
              <div class="mt-4">
                <label class="text-muted">Documento</label>
                <div class="text-sm" *ngIf="finance?.doc?.length === 11">
                  CPF - {{ finance?.doc! | mask: '000.000.000-99' }}
                </div>
                <div class="text-sm" *ngIf="finance?.doc?.length === 14">
                  CNPJ - {{ finance?.doc! | mask: '00.000.000/0000-00' }}
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-5 p-0" *ngIf="financeAccountCreated === false">
              <h5>Dados para resgate</h5>
              <p class="">Preencha o <strong>Nome</strong> e <strong>Documento</strong> para cadastrar a chave PIX
                relacionada a esses dados</p>
            </div>
            <div class="col-md-4 mt-5 p-0" *ngIf="financeAccountCreated === true">
              <h5>Dados para resgate</h5>
              <p class="">Chave PIX para recebimento dos resgates</p>
            </div>
            <div class="col-md-7 mt-5 p-0 rounded-lg bg-white">
              <form *ngIf="financeAccountCreated === false" #financeCreateAccountForm="ngForm" class="form"
                (ngSubmit)="financeCreateAccountForm.valid && onCreateFinanceAccount()">
                <div class="p-4 pb-0 border-bottom border-light">
                  <div class="form-group row">
                    <label class="col-lg-4 col-form-label">
                      Nome
                    </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" style="height: 40px;" name="fullName"
                        [(ngModel)]="finance.fullName" #fullName="ngModel"
                        [ngClass]="{ 'is-invalid': financeCreateAccountForm.submitted && fullName.invalid }" required
                        fullName maxlength="25" />
                      <div *ngIf="financeCreateAccountForm.submitted && fullName.invalid" class="invalid-feedback">
                        <div *ngIf="fullName.errors?.required">Esse campo é obrigatório</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-4 col-form-label" for="inputAgencia">
                      Documento
                    </label>
                    <div class="col-lg-8">
                      <input [(ngModel)]="finance.doc" mask="CPF_CNPJ" [dropSpecialCharacters]="true"
                        [showMaskTyped]="false" placeHolderCharacter="*" type="tel" class="form-control"
                        style="height: 40px;" name="doc" #doc="ngModel"
                        [ngClass]="{ 'is-invalid': financeCreateAccountForm.submitted && doc.invalid }" required doc />
                      <div *ngIf="financeCreateAccountForm.submitted && doc.invalid" class="invalid-feedback">
                        <div *ngIf="doc.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="doc.errors?.minlength">Documento inválido</div>
                      </div>
                    </div>
                  </div>
                  <div class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
                    <i class="bi bi-info-circle-fill mr-3"></i>
                    <small><strong>Nome completo</strong> e <strong>Documento</strong> não poderão ser alterados
                      depois.</small>
                  </div>
                </div>
                <div class="py-3 px-4 border-top border-light align-items-end d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary">
                    Confirmar
                  </button>
                </div>
              </form>
              <form *ngIf="financeAccountCreated === true" #financeUpdateAccountForm="ngForm" class="form"
                (ngSubmit)="financeUpdateAccountForm.valid && onUpdateFinanceAccount()">
                <div class="p-4 pb-0 border-bottom border-light">
                  <div class="form-group row">
                    <label class="col-lg-4 col-form-label" for="selecObject">Tipo de chave</label>
                    <div class="col-lg-auto">
                      <select
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && financePixKeyType.invalid }"
                        class="custom-select my-1 mr-sm-2" id="selecObject" #financePixKeyType="ngModel"
                        [(ngModel)]="this.updateFinance.pixKeyType" (change)="onPixKeyTypeChange($event)"
                        name="financePixKeyType" required financePixKeyType>
                        <option [value]="pixKeyType.CPF">
                          CPF
                        </option>
                        <option [value]="pixKeyType.CNPJ">
                          CNPJ
                        </option>
                        <option [value]="pixKeyType.EMAIL">
                          E-mail
                        </option>
                        <option [value]="pixKeyType.PHONE">
                          Telefone
                        </option>
                        <option [value]="pixKeyType.RANDOM_KEY">
                          Aleatória
                        </option>
                      </select>
                      <div *ngIf="financeUpdateAccountForm.submitted && financePixKeyType.invalid"
                        class="invalid-feedback">
                        <div *ngIf="financePixKeyType.errors?.required">Esse campo é obrigatório</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-4 col-form-label" for="inputAgencia">
                      Chave PIX
                    </label>
                    <div class="col-lg-8" *ngIf="updateFinance.pixKeyType === pixKeyType.CPF">
                      <input [(ngModel)]="updateFinance.pixKey" mask="000.000.000-00" [dropSpecialCharacters]="true"
                        [showMaskTyped]="false" type="tel" class="form-control" style="height: 40px;" name="pixKeyCpf"
                        #pixKeyCpf="ngModel"
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && pixKeyCpf.invalid }" required
                        pixKeyCpf />
                      <div *ngIf="financeUpdateAccountForm.submitted && pixKeyCpf.invalid" class="invalid-feedback">
                        <div *ngIf="pixKeyCpf.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="pixKeyCpf.errors?.minlength">CPF inválido</div>
                      </div>
                    </div>
                    <div class="col-lg-8" *ngIf="updateFinance.pixKeyType === pixKeyType.CNPJ">
                      <input [(ngModel)]="updateFinance.pixKey" mask="00.000.000/0000-00" [dropSpecialCharacters]="true"
                        [showMaskTyped]="false" placeHolderCharacter="*" type="tel" class="form-control"
                        style="height: 40px;" name="pixKeyCnpf" #pixKeyCnpf="ngModel"
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && pixKeyCnpf.invalid }" required
                        pixKeyCnpf />
                      <div *ngIf="financeUpdateAccountForm.submitted && pixKeyCnpf.invalid" class="invalid-feedback">
                        <div *ngIf="pixKeyCnpf.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="pixKeyCnpf.errors?.minlength">CNPJ inválido</div>
                      </div>
                    </div>
                    <div class="col-lg-8" *ngIf="updateFinance.pixKeyType === pixKeyType.EMAIL">
                      <input [(ngModel)]="updateFinance.pixKey" placeHolderCharacter="*" type="email"
                        class="form-control" style="height: 40px;" name="pixKeyEmail" #pixKeyEmail="ngModel"
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && pixKeyEmail.invalid }" required
                        pixKeyEmail />
                      <div *ngIf="financeUpdateAccountForm.submitted && pixKeyEmail.invalid" class="invalid-feedback">
                        <div *ngIf="pixKeyEmail.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="pixKeyEmail.errors?.minlength">Cpf inválido</div>
                      </div>
                    </div>
                    <div class="col-lg-8" *ngIf="updateFinance.pixKeyType === pixKeyType.PHONE">
                      <input [(ngModel)]="updateFinance.pixKey" mask="+00 (00) 0 0000-0000"
                        [dropSpecialCharacters]="true" [showMaskTyped]="false" placeHolderCharacter="*" type="text"
                        class="form-control" style="height: 40px;" name="pixKeyPhone" #pixKeyPhone="ngModel"
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && pixKeyPhone.invalid }" required
                        pixKeyPhone />
                      <div *ngIf="financeUpdateAccountForm.submitted && pixKeyPhone.invalid" class="invalid-feedback">
                        <div *ngIf="pixKeyPhone.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="pixKeyPhone.errors?.minlength">Telefone inválido</div>
                      </div>
                    </div>
                    <div class="col-lg-8" *ngIf="updateFinance.pixKeyType === pixKeyType.RANDOM_KEY">
                      <input [(ngModel)]="updateFinance.pixKey" placeHolderCharacter="*" type="tel" class="form-control"
                        style="height: 40px;" name="pixKeyRandom" #pixKeyRandom="ngModel"
                        [ngClass]="{ 'is-invalid': financeUpdateAccountForm.submitted && pixKeyRandom.invalid }"
                        required pixKeyRandom />
                      <div *ngIf="financeUpdateAccountForm.submitted && pixKeyRandom.invalid" class="invalid-feedback">
                        <div *ngIf="pixKeyRandom.errors?.required">Esse campo é obrigatório</div>
                        <div *ngIf="pixKeyRandom.errors?.minlength">Chave pix inválida</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-3 px-4 border-top border-bottom border-light align-items-end d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary">
                    Salvar alterações
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="slide-content">
    <app-finance-slide-panel #financeSlidePanel [isOpen]="isSlidePanelOpen" [operation]="selectedOperation"
      (eventClose)="closeFinanceSlidePanel()"></app-finance-slide-panel>
  </div>
</div>
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthGuard } from "../auth/auth.guard";

export interface FeatureToggle {
  thermometer: boolean,
  playerInvitation: boolean,
  pushNotification: boolean,
}

@Injectable({
  providedIn: 'root',
}) export class FeatureToggleService {

  private features: FeatureToggle = {
    thermometer: false,
    playerInvitation: false,
    pushNotification: false,
  };

  constructor(
    private auth: AuthGuard,
  ) {}

  getFeatures(): Observable<FeatureToggle> {
    const token: any = this.auth.getTokenData();
    this.features.thermometer = token.featureToggle.thermometer;
    this.features.pushNotification = token.featureToggle.pushNotification;
    this.features.playerInvitation = token.featureToggle.playerInvitation;
    return of(this.features);
  }
}
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { PlayerThermometerService } from './player-thermometer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Criteria, PageableData, Player, TotalPlayersResponse } from './player-thermometer.model';
import { UserInfoModalComponent } from 'src/app/modals/user-info-modal/user-info-modal.component';
import { SortEvent } from 'src/app/components/ngbd-sortable-header/ngbd-sortable-header.component';
import { PushNotificationComponent } from 'src/app/modals/push-notification/push-notification.component';
import { Temperature } from 'src/app/shared/player.enum';
import { NotificationService } from 'src/app/shared/notification.service';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';
import { FeatureToggleService } from 'src/app/shared/feature-toggle.service';

@Component({
  selector: 'app-player-thermometer',
  templateUrl: './player-thermometer.component.html',
  styleUrls: ['./player-thermometer.component.scss'],
})
export class PlayerThermometerComponent implements OnInit {
  @ViewChild('slidePanel') public slidePanel!: SlidePanelComponent;
  @ViewChild('userInfoModal', { static: true }) public userInfoModal!: UserInfoModalComponent;
  @ViewChild('pushNotificationModal', { static: true }) public pushNotificationModal!: PushNotificationComponent;
  subscription: Subject<void> = new Subject();
  searchChanged: Subject<Criteria> = new Subject<Criteria>();
  periodFilter: Subject<Criteria> = new Subject<Criteria>();
  temperatureLabel: Subject<Criteria> = new Subject<Criteria>();
  isThermometerActive: Subject<Criteria> = new Subject<Criteria>();
  
  criteria: Criteria = new Criteria();
  isSlidePanelOpen: boolean = false;
  playerInfo!: any;
  playerCard: any = {};
  data: any[] = [];
  openModal = false;
  hasFocus: boolean = false;
  hasSelectTemperatureFocus: boolean = false;
  hasPeriodFocus: boolean = false;
  isVisible: boolean = true;
  column: string = '';
  directions: { [key: string]: string } = {};
  selectedPeriodLabel: string = "Últimos 30 dias";

  selectedPlayersId: any[] = [];
  deselectedPlayersId: any[] = [];

  isAllPlayersChecked: boolean = false;
  isAllPlayersAcrossPagesSelected: boolean = false;
  isAllPlayersCheckIndeterminate: boolean = false;
  showClearAllPlayersBtn: boolean = false;
  notifyPlayerListChange: boolean = false;
  isSkeletonLoading: boolean = true;
  isSkeletonLoadingTotal: boolean = true;

  temperatureFilter!: any;

  maxSize = 3;
  rotatePagination = true;

  isThermometerAvailable!: boolean;
  isPushNotificationAvailable!: boolean;

  constructor(
    public playerThermometerService: PlayerThermometerService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private sidebarControlService: SidebarService,
    private featureToggleService: FeatureToggleService
  ) {
    this.searchChanged.pipe(debounceTime(700))
      .subscribe(searchCriteria => this.updatePlayerList(searchCriteria));
    this.periodFilter.pipe(debounceTime(0))
      .subscribe(periodFilter => {
        this.updatePlayerList(periodFilter);
        this.updateTotalPlayersCard(periodFilter.datePeriod);
      }
    );
    this.temperatureLabel.pipe(debounceTime(0))
      .subscribe(temperatureLabel => this.updatePlayerList(temperatureLabel));
    this.isThermometerActive.pipe(debounceTime(0))
      .subscribe(isThermometerActive => this.updatePlayerList(isThermometerActive));
  }

  ngOnInit(): void {
    this.updatePlayerList(this.criteria);
    this.updateTotalPlayersCard(this.criteria.datePeriod);
    this.getFeaturesStatus();
  }

  public get temperatureStatus(): typeof Temperature {
    return Temperature;
  }

  getFeaturesStatus() {
    this.featureToggleService.getFeatures().subscribe((status) => {
      this.isThermometerAvailable = status.thermometer;
      this.isPushNotificationAvailable = status.pushNotification;
    });
  }

  openSlidePanel(item: any) {
    if (item) {
      this.isSlidePanelOpen = true;
      this.playerInfo = item;
      this.sidebarControlService.toggleSidebarVisibility(false);
    }
  }

  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.updatePlayerList(this.criteria);
  }

  closeSlidePanel() {
    this.isSlidePanelOpen = false;
    this.playerInfo = null;
    this.sidebarControlService.toggleSidebarVisibility(true);
  }

  openUserInfoModal(player: Player): void {
    this.spinner.show();
    this.userInfoModal.openModal(player);
  }  

  openPushNotificationModal(): void {
    this.spinner.show();
    if(this.isAllPlayersAcrossPagesSelected) {
      this.deselectedPlayersId.length !== 0 ? 
      this.pushNotificationModal.openModal(this.criteria.totalDocs, false, [], this.deselectedPlayersId) :
      this.pushNotificationModal.openModal(this.criteria.totalDocs,this.isAllPlayersAcrossPagesSelected, [], []);
    } else {
      this.pushNotificationModal.openModal('', false, this.selectedPlayersId, [])
    }

    this.isSlidePanelOpen = false;
    this.playerInfo = null;
  }

  closePushNotificationModal(): void {
    this.playerInfo = null;
    this.clearAllPlayersSelection();
  }

  onSearchChange(criteria: Criteria): void {
    if(!this.criteria.searchTerm && (this.isAllPlayersAcrossPagesSelected || this.isAllPlayersChecked)) {
      this.isAllPlayersChecked = false;
      this.isAllPlayersAcrossPagesSelected = false;
      this.isAllPlayersCheckIndeterminate = true;
      this.showClearAllPlayersBtn = false;

      this.updateAllPlayersCheckState();
    }
    this.searchChanged.next(criteria);
  }

  updatePlayerList(criteria: Criteria): any {
    this.isSkeletonLoading = true;
   
    this.criteria.page = criteria.page;

    this.playerThermometerService
      .getPlayerThermometerdData(criteria)
      .pipe(
        takeUntil(this.subscription.asObservable()),
      )
      .subscribe((response: PageableData<Player>) => {
        this.data = response.data.data;
        this.criteria.totalDocs = response.data.totalDocs;
        this.criteria.page = response.data.page;
        this.criteria.limit = response.data.limit;
        this.criteria.totalPages = response.data.totalPages;
        this.onPageChange(response.data.data);

        this.isSkeletonLoading = false;

        if (this.notifyPlayerListChange) {
          this.showPlayerListNotification();
          this.notifyPlayerListChange = false;
        }
      });
  }

  updateTotalPlayersCard(datePeriod: number): any {
    this.isSkeletonLoadingTotal = true;

    const date = new Date();
    const dateRange = new Date(date);
    dateRange.setDate(date.getDate() - datePeriod);

    this.playerThermometerService.getTotalPlayers(dateRange.toISOString())
      .subscribe(
        (response: TotalPlayersResponse) => {
        this.playerCard = {
          total: response.data.total,
          lastPlayers: response.data.lastPlayers ? response.data.lastPlayers : 0,
        };

        this.isSkeletonLoadingTotal = false;
        },
        (error) => {
          this.isSkeletonLoadingTotal = false;
          console.log(error)
        }
      )
  }

  showPlayerListNotification() {
    if(this.criteria.datePeriod !== 1) {
      this.notificationService.showInfo(
        `Exibindo os ${this.criteria.totalDocs} jogadores que realizaram alguma transação nos últimos 
        ${this.criteria.datePeriod} dias`, '');
    } else {
      this.notificationService.showInfo(
        `Exibindo o total de jogadores que realizaram alguma transação por todo o período`, '');
    }
  }

  onSort({ column, direction }: SortEvent) {
    if (this.column && this.column !== column) {
      this.directions[this.column] = '';
    }
    this.column = column;
    this.directions[column] = direction;
  }

  getDirection(column: string): string {
    return this.directions[column] || '';
  }

  isSelected(player: any) {
    return this.isAllPlayersAcrossPagesSelected ? !this.deselectedPlayersId.includes(player._id) : this.selectedPlayersId.includes(player._id);
  }

  selectPlayer(player: any) {
    const playersId = player._id;
    if (!this.selectedPlayersId.includes(playersId)) {
      this.selectedPlayersId.push(playersId);

      this.deselectedPlayersId = this.deselectedPlayersId.filter(id => id !== playersId);

      if (this.isAllPlayersAcrossPagesSelected && this.deselectedPlayersId.length === 0) {
        this.isAllPlayersChecked = true;
        this.showClearAllPlayersBtn = true;
      }

    }
    
    if (this.selectedPlayersId.length === this.criteria.totalDocs) {
      this.showClearAllPlayersBtn = true;
      this.isAllPlayersAcrossPagesSelected = true;
    }
  }

  deselectPlayer(player: any) {
    const playersId = player._id;

    this.showClearAllPlayersBtn = false;
    this.selectedPlayersId = this.selectedPlayersId.filter(id => id !== playersId);

    if (this.isAllPlayersAcrossPagesSelected) {
      if (!this.deselectedPlayersId.includes(playersId)) {
        this.deselectedPlayersId.push(playersId);
      }
    }
  }

  onCheckChange(player: any, event: any) {
    if (event.target.checked) {
      this.selectPlayer(player);
    } else {
      this.deselectPlayer(player);
      this.isAllPlayersCheckIndeterminate = true
    }
    this.updateAllPlayersCheckState();
  }

  onCheckAllChange(event: any) {
    this.isAllPlayersChecked = event.target.checked;
    this.isAllPlayersCheckIndeterminate = false;

    const playerIds = this.data.map(player => player._id);

    if(this.isAllPlayersChecked) {
      this.selectedPlayersId = [
        ...this.selectedPlayersId,
        ...playerIds.filter(id => !this.selectedPlayersId.includes(id))
      ];
      this.deselectedPlayersId = this.deselectedPlayersId.filter(id => !playerIds.includes(id));
    } else {
      if (this.isAllPlayersAcrossPagesSelected) {
        this.deselectCurrentPagePlayers();
      } else {
        this.selectedPlayersId = this.selectedPlayersId.filter(id => !playerIds.includes(id));
      }
    }

    if (this.selectedPlayersId.length === this.criteria.totalDocs) {
      this.showClearAllPlayersBtn = true;
      this.isAllPlayersAcrossPagesSelected = true;
    }

    if (this.criteria.totalDocs === this.deselectedPlayersId.length) {
      this.isAllPlayersAcrossPagesSelected = false
    }

    this.updateAllPlayersCheckState();
  }

  selectAllPlayersAcrossPagesToggle() {
    this.selectedPlayersId = []
    this.deselectedPlayersId = []
    this.isAllPlayersAcrossPagesSelected = true;
    this.isAllPlayersChecked = true;
    this.showClearAllPlayersBtn = true;
    this.data.forEach(player => {
      if (!this.selectedPlayersId.includes(player._id)) {
        this.selectedPlayersId.push(player._id);
      }
      this.deselectedPlayersId = this.deselectedPlayersId.filter(id => id !== player._id);
    });
    this.updateAllPlayersCheckState();
  }

  deselectCurrentPagePlayers() {
    this.showClearAllPlayersBtn = false;

    this.data.forEach(player => {
      if (!this.deselectedPlayersId.includes(player._id)) {
        this.deselectedPlayersId.push(player._id);
      }
      this.selectedPlayersId = this.selectedPlayersId.filter(id => id !== player._id);
    });

    this.updateAllPlayersCheckState();
  }

  updateAllPlayersCheckState() {
    const totalPlayers = this.data.length;
    const selectedPlayersCount = this.data.reduce((count, player) =>
      count + (this.isSelected(player) ? 1 : 0), 0);

    const allPlayersSelected = selectedPlayersCount === totalPlayers;
    const hasPlayerSelection = selectedPlayersCount > 0;

    if (this.isAllPlayersAcrossPagesSelected) {
      this.isAllPlayersChecked = allPlayersSelected;
      this.isAllPlayersCheckIndeterminate = hasPlayerSelection && !allPlayersSelected;
    } else {
      const noDeselections = this.deselectedPlayersId.length === 0;
      this.isAllPlayersChecked = allPlayersSelected && noDeselections;
      this.isAllPlayersCheckIndeterminate = hasPlayerSelection && !allPlayersSelected;
    }

    if (this.deselectedPlayersId.length === this.criteria.totalDocs) {
      this.isAllPlayersAcrossPagesSelected = false;
    }

    if (this.selectedPlayersId.length === 0 && !this.isAllPlayersAcrossPagesSelected) {
      this.showClearAllPlayersBtn = false;
      this.selectedPlayersId.length = 0;
      this.deselectedPlayersId.length = 0;
    }

    this.checkAllPlayersCheckState(this.isAllPlayersCheckIndeterminate, this.isAllPlayersChecked);
  }

  onPageChange(newData: any[]) {
    this.data = newData;
    this.updateAllPlayersCheckState();
  }

  checkAllPlayersCheckState(indeterminate: any, isChecked: any) {
    const allPlayersCheckElement = <HTMLInputElement>document.getElementById('allPlayersCheck');
    if (allPlayersCheckElement) {
      allPlayersCheckElement.indeterminate = indeterminate;
      allPlayersCheckElement.checked = isChecked;
    }
  }

  clearAllPlayersSelection() {
    this.isAllPlayersChecked = false;
    this.isAllPlayersAcrossPagesSelected = false;
    this.isAllPlayersCheckIndeterminate = false;
    this.showClearAllPlayersBtn = false;
    this.selectedPlayersId = [];
    this.deselectedPlayersId = [];
    this.updateAllPlayersCheckState();
  }

  getSelectedPlayersCount() {
    if(this.isAllPlayersAcrossPagesSelected && this.deselectedPlayersId.length >= 1) {
      return this.criteria.totalDocs - this.deselectedPlayersId.length
    } else {
     return this.criteria.totalDocs 
    }
  }

  setPeriodFilter(criteria: Criteria) {
    this.clearAllPlayersSelection();
    this.periodFilter.next(criteria);
  }

  setTemperatureFilter(criteria: Criteria) {
    this.clearAllPlayersSelection();
    this.temperatureLabel.next(criteria);
  }

  setIsThermometerActive(criteria: Criteria) {
    this.clearAllPlayersSelection();
    this.isThermometerActive.next(criteria);
  }

  private temperatureIcons: { [key in Temperature]: string } = {
    [Temperature.HOT]: 'assets/icons/thermometer/hot-status.svg',
    [Temperature.WARM]: 'assets/icons/thermometer/warm-status.svg',
    [Temperature.COLD]: 'assets/icons/thermometer/cold-status.svg'
  };

  setTemperatureIcons(temperatureFactor: string): string {
    const status: Temperature | '' = '';

    if (temperatureFactor === 'cold') {
      return this.temperatureIcons['COLD'];
    } else if (temperatureFactor === 'warm') {
      return this.temperatureIcons['WARM'];
    } else if (temperatureFactor === 'hot') {
      return this.temperatureIcons['HOT'];
    } else {
      return 'assets/icons/thermometer/none-status.svg';
    }
  }

  onDropdownPeriod(days: number) {
    const periodLabels: { [key: number]: string } = {
      7: 'Últimos 7 dias',
      15: 'Últimos 15 dias',
      30: 'Últimos 30 dias',
      1: 'Todo o período',
    };
  
    this.notifyPlayerListChange = true;
    this.criteria.datePeriod = days;
    this.setPeriodFilter(this.criteria);
    this.selectedPeriodLabel = periodLabels[days] || 'Últimos 30 dias';
  }
}

<app-transfer-modal #transferModal (paymentRefounded)="onPaymentRefounded()">
</app-transfer-modal>
<app-withdraw-modal #withdrawModal (paymentConfirmed)="onPaymentWithdrawConfirmed()">
</app-withdraw-modal>
<app-user-info-modal #userInfoModal></app-user-info-modal>

<div style="position: relative;">
  <div class="form-inline p-3 m-0 d-flex justify-content-between">
    <div class="input-group">
      <input class="form-control border-right-0" type="text" placeholder="Buscar..." name="searchTerm"
        (input)="onSearchChange(criteria)" [(ngModel)]="criteria.searchTerm" />
      <!-- <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"> -->
      <div class="input-group-append">
        <span class="input-group-text bg-white">
          <i class="bi bi-search"></i>
        </span>
      </div>
    </div>
    <select title="transaction-filter" class="form-control custom-select my-1" [(ngModel)]="filterByType"
      (ngModelChange)="onFilterChange($event)">
      <option selected [value]="transactionStatus.ALL">{{transactionStatusNamed[transactionStatus.ALL]}}</option>
      <option *ngIf="(criteria.listByType === transactionType.PIX)" [value]="transactionStatus.DONE">
        {{transactionStatusNamed[transactionStatus.DONE]}}</option>
      <option [value]="transactionStatus.WAITING_PAYMENT">{{transactionStatusNamed[transactionStatus.WAITING_PAYMENT]}}
      </option>
      <option [value]="transactionStatus.ERROR">{{transactionStatusNamed[transactionStatus.ERROR]}}</option>
      <option [value]="transactionStatus.PROCESSING">{{transactionStatusNamed[transactionStatus.PROCESSING]}}</option>
      <option *ngIf="(criteria.listByType === transactionType.PIX)" [value]="transactionStatus.REFUNDED">
        {{transactionStatusNamed[transactionStatus.REFUNDED]}}
      </option>
      <option *ngIf="(criteria.listByType === transactionType.WITHDRAW)" [value]="transactionStatus.PAID">
        {{transactionStatusNamed[transactionStatus.PAID]}}</option>
      <option *ngIf="(criteria.listByType === transactionType.PIX)" [value]="transactionStatus.NOT_PAID">
        {{transactionStatusNamed[transactionStatus.NOT_PAID]}}</option>
      <option *ngIf="(criteria.listByType === transactionType.PIX)" [value]="transactionStatus.SENDING_CHIPS">
        {{transactionStatusNamed[transactionStatus.SENDING_CHIPS]}}</option>
      <option *ngIf="(criteria.listByType === transactionType.WITHDRAW)" [value]="transactionStatus.WITHDRAWING_CHIPS">
        {{transactionStatusNamed[transactionStatus.WITHDRAWING_CHIPS]}}</option>
    </select>
  </div>
  
  <div class="table-responsive table table-striped table-hover">
    <table class="table text-nowrap table-borderless m-0">
      <thead class="border-top border-bottom border-light">
        <tr class="text-muted">
          <th scope="col">Nome</th>
          <th scope="col" class="text-center">Jogador ID</th>
          <th scope="col" class="text-center">Data</th>
          <th scope="col" class="text-center">Hora</th>
          <th scope="col" class="text-center">Fichas</th>
          <th scope="col" class="text-center">Valor</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-top border-bottom align-middle" style="height: 70px;" *ngFor="let transaction of transactions"
          [ngClass]="{'selected' : transaction === selectedTransaction}">
          <td class="align-middle">
            <ngb-highlight class="btn btn-link text-primary p-0" (click)="openUserInfoModal(transaction)"
              [result]="transaction._user.name" [term]="criteria.searchTerm">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="transaction._gameAccount.playerAccountId" [term]="criteria.searchTerm">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="transaction.createdAt | date: 'd/M/yy'">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="transaction.createdAt | date: 'HH:mm'">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="transaction.amount/100 | number">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="transaction.amount/100 | currency">
            </ngb-highlight>
          </td>
          <td [ngSwitch]="transaction.status" class="text-center font-weight-light align-middle">
            <span class="badge badge-pill badge-info font-weight-normal"
              *ngSwitchCase='transactionStatus.PROCESSING'>{{transactionStatusNamed[transactionStatus.PROCESSING]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal"
              *ngSwitchCase='transactionStatus.DONE'>{{transactionStatusNamed[transactionStatus.DONE]}}</span>
            <span class="badge badge-pill badge-dark font-weight-normal"
              *ngSwitchCase='transactionStatus.REFUNDED'>{{transactionStatusNamed[transactionStatus.REFUNDED]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal"
              *ngSwitchCase='transactionStatus.WAITING_PAYMENT'>{{transactionStatusNamed[transactionStatus.WAITING_PAYMENT]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal"
              *ngSwitchCase='transactionStatus.PAID'>{{transactionStatusNamed[transactionStatus.PAID]}}</span>
            <span class="badge badge-pill badge-danger text-wrap font-weight-normal"
              *ngSwitchCase='transactionStatus.ERROR'>{{transactionStatusNamed[transactionStatus.ERROR]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal"
              *ngSwitchCase='transactionStatus.WITHDRAWING_CHIPS'>{{transactionStatusNamed[transactionStatus.WITHDRAWING_CHIPS]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal"
              *ngSwitchCase='transactionStatus.SENDING_CHIPS'>{{transactionStatusNamed[transactionStatus.SENDING_CHIPS]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal"
              *ngSwitchCase='transactionStatus.NOT_PAID'>{{transactionStatusNamed[transactionStatus.NOT_PAID]}}</span>
          </td>
          <td class="text-center align-middle" *ngIf="criteria.listByType === transactionType.PIX"
            [ngSwitch]="transaction.status">
            <button *ngSwitchDefault type="button" class="nav-btn btn btn-light btn-sm border"
              (click)="openTransactionSlidePanel(transaction)" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span class="material-symbols-outlined">chevron_right</span>
            </button>
          </td>
          <td class="text-center align-middle" *ngIf="criteria.listByType === transactionType.WITHDRAW">
            <ng-container *ngIf="transaction.status === transactionStatus.WAITING_PAYMENT; else elseTemplate">
              <button type="button" class="btn btn-primary btn-sm" (click)="openWithdrawModal(transaction)">Pagar</button>
            </ng-container>
            <ng-template #elseTemplate>
              <button type="button" class="nav-btn btn btn-light btn-sm border"
                (click)="openTransactionSlidePanel(transaction)" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span class="material-symbols-outlined">chevron_right</span>
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div *ngIf="this.transactions.length === 0">
    <div *ngIf="filterByType === transactionStatus.ALL" class="alert alert-light text-center mx-auto mb-0" role="alert">
      Ainda não houve transações feitas em {{transactionTypeNamed[criteria.listByType]}}.
    </div>
    <div *ngIf="filterByType !== transactionStatus.ALL" class="alert alert-light text-center mx-auto mb-0" role="alert">
      Nenhuma transação '{{transactionStatusNamed[filterByType]}}' foi encontrada
    </div>
  </div>
  
  <div class="pagination-container row table-responsive bg-white">
    <p *ngIf="this.transactions.length > 0" class="mt-4 mx-3">
      <small class="font-weight-light">
        Exibindo
        <span class="font-weight-normal">{{criteria.page}}</span>
        de
        <span class="font-weight-normal">{{criteria.totalPages}}</span>
        {{criteria.totalPages > 1 ? 'páginas': 'página'}}
      </small>
    </p>
    <ngb-pagination 
      class="mt-3 mx-3"
      [collectionSize]="criteria.totalDocs"
      [(page)]="criteria.page"
      [pageSize]="criteria.limit"
      [maxSize]="maxSize"
      [rotate]="rotatePagination"
      [ellipses]="false"
      [boundaryLinks]="true"
      (pageChange)="jumpPagination($event)"
      [hidden]="criteria.page > criteria.totalDocs">
    </ngb-pagination>
  </div>
</div>

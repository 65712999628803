<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">
          {{titleText}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body confirm-subtitle p-4">
        <h5>Dados do Jogador</h5>
        <div class="bg-light px-4 py-3 text-dark rounded-lg">
          <div class="">
            <b>Nome:</b>
            {{name}}
          </div>
          <a href="mailto: {{email}}" class="d-flex align-items-center">
            <i class="bi bi-envelope-fill mr-2"></i>
            {{email}}
            <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
          </a>
          <a href="https://wa.me/+55{{ddd + phoneNumber}}" target="_blank" class="d-flex align-items-center">
            <i class="bi bi-whatsapp mr-2"></i>
            {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
            <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
          </a>
          <div *ngIf="action !== playerAction.CUSTOMIZE_WITHDRAW">
            <div *ngIf="withdrawSettings.maxValueToWithdraw">
              <b>Valor máximo de saque:</b>
              {{formattedCurrencyValue}}
            </div>
            <div *ngIf="withdrawSettings.maxWithdrawPerDay">
              <b>Quantidade de solicitações de saques:</b>
              {{withdrawSettings.maxWithdrawPerDay}}
            </div>
          </div>
        </div>
        <h5 *ngIf="action === playerAction.CUSTOMIZE_WITHDRAW" class="pt-4">Personalizar saque na conta <b>{{playerAccountId}}</b></h5>
        <div *ngIf="action === playerAction.CUSTOMIZE_WITHDRAW" class="bg-light px-4 py-3 text-dark rounded-lg mt-2">
          <form #playerControlForm="ngForm" class="form">
            <div class="form-group row align-items-center">
              <label class="col-lg-7 col-form-label" for="maxValueToWithdraw">Valor máximo de saque automático</label>
              <div class="ml-3 mb-2 custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="maxValueToWithdrawActive"
                  [(ngModel)]="withdrawSettings.maxValueToWithdrawActive" name="maxValueToWithdrawActive">
                <label class="custom-control-label" for="maxValueToWithdrawActive">
                  <span *ngIf="withdrawSettings.maxValueToWithdrawActive; else showDesativado"
                    style="font-size: 0.8em; color: #888;">Ativado</span>
                  <ng-template #showDesativado>
                    <span style="font-size: 0.8em; color: #888;">Desativado</span>
                  </ng-template>
                </label>
              </div>
              <div class="col-lg-5 pt-2">
                <div *ngIf="withdrawSettings.maxValueToWithdrawActive" class="input-group mb-0 mr-sm-2 mr-4">
                  <div class="input-group-prepend">
                    <div class="input-group-text">R$</div>
                  </div>
                  <input type="tel" autocomplete="off" class="form-control" name="maxValueToWithdraw"
                    [(ngModel)]="withdrawSettings.maxValueToWithdraw" #maxValueToWithdraw="ngModel"
                    [ngClass]="{ 'is-invalid': playerControlForm.submitted && maxValueToWithdraw.invalid }" required maxValueToWithdraw currencyMask />
                  <div class="invalid-feedback">
                    <div>Esse campo é obrigatório</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-lg-7 col-form-label" for="maxWithdrawPerDay">Limite de saques diário</label>
              <div class="ml-3 mb-2 custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="maxWithdrawPerDayActive"
                  [(ngModel)]="withdrawSettings.maxWithdrawPerDayActive" name="maxWithdrawPerDayActive">
                <label class="custom-control-label" for="maxWithdrawPerDayActive">
                  <span *ngIf="withdrawSettings.maxWithdrawPerDayActive; else showDesativado"
                    style="font-size: 0.8em; color: #888;">Ativado</span>
                  <ng-template #showDesativado>
                    <span style="font-size: 0.8em; color: #888;">Desativado</span>
                  </ng-template>
                </label>
              </div>
              <div *ngIf="withdrawSettings.maxWithdrawPerDayActive" class="col-lg-5 pt-2">
                <input type="number" autocomplete="off" maxlength="3" min="0" class="form-control" name="maxWithdrawPerDay"
                  [(ngModel)]="withdrawSettings.maxWithdrawPerDay" #maxWithdrawPerDay="ngModel"
                  [ngClass]="{ 'is-invalid': playerControlForm.submitted && maxWithdrawPerDay.invalid }" required maxWithdrawPerDay inputmode="numeric" oninput="this.value = this.value.replace(/\D+/g, '')" />
                <div class="invalid-feedback">
                  <div>Esse campo é obrigatório</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
          <i class="bi bi-info-circle-fill mr-3"></i>
          <small>
            {{alertText}}
          </small>
        </div>
      </div>
      <div class="modal-footer bg-light border-light d-flex justify-content-between">
        <button type="button" class="btn btn-outline-white border-primary text-primary" (click)="closeModal()">
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">
          {{buttonText}}
        </button>
      </div>
    </div>
  </div>
</div>

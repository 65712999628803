import { TransactionStatus, TransactionStatusNamed } from 'src/app/shared/transaction.enum';
import { TransactionResponse } from './../../pages/transactions/transaction.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Transaction } from 'src/app/pages/transactions/transaction.model';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { WithdrawSettings } from 'src/app/shared/player.interface';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss'],
})

export class UserInfoModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  title!: string;
  transaction!: Transaction;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  withdrawSettings: WithdrawSettings = {} as WithdrawSettings;
  showAccountCustomData!: boolean;

  @Output() paymentRefounded = new EventEmitter();

  constructor(
    public service: TransactionService,
    private notificationService: NotificationService,
  ) { }

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  public get formattedCurrencyValue(): string {
    return this.withdrawSettings.maxValueToWithdraw.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  /**
   * Open modal
   */
  public openModal(playerTransaction: any): void {
    this.autoShownModal.show();
    this.withdrawSettings = playerTransaction as WithdrawSettings;
    this.withdrawSettings.maxValueToWithdraw = playerTransaction.maxValueToWithdraw / 100;
    this.showAccountCustomData = playerTransaction.maxValueToWithdraw >= 0 || 
      playerTransaction.maxWithdrawPerDay >= 0;
    this.name = playerTransaction._user.name;
    this.email = playerTransaction._user.email;
    this.ddd = playerTransaction._user.ddd;
    this.phoneNumber = playerTransaction._user.phoneNumber;
    this.playerAccountId = playerTransaction.playerAccountId ?
      playerTransaction.playerAccountId :
      playerTransaction._gameAccount.playerAccountId;
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { DoubleCheckModalComponent } from './../../modals/double-check-modal/double-check-modal.component';
import { UserInfoModalModule } from './../../modals/user-info-modal/user-info-modal.module';
import { PlayerComponent } from './player.component';
import { PlayerListComponent } from './players/player-list.component';
import { NgbdSortableHeader } from './sortable.directive';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { InviteNewPlayersModule } from 'src/app/components/invite-new-players/invite-new-players.module';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: 0,
  max: 100000000,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    NgxMaskModule,
    RouterModule,
    UserInfoModalModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    InviteNewPlayersModule
  ],
  declarations: [
    PlayerListComponent,
    PlayerComponent,
    NgbdSortableHeader,
    DoubleCheckModalComponent
  ],
  exports: [
    PlayerComponent
  ],
  bootstrap: [
    PlayerComponent
  ]
})
export class PlayerModule { }

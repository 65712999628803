import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TransactionStatus } from 'src/app/shared/transaction.enum';
import { TRANSACTION_URL, CLUB_PAYMENT_CASH_OUT_URL } from './../../config/settings';
import { Pageable, Transaction, TransactionResponse } from './transaction.model';
import { NotificationService } from 'src/app/shared/notification.service';


@Injectable({ providedIn: 'root' })
export class TransactionService {

  transaction!: Transaction;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService
  ) { }

  getTransactionById(transactionId: any): Observable<TransactionResponse> {
    this.spinner.show();
    return this.http.get<TransactionResponse>(`${TRANSACTION_URL}/${transactionId}`)
      .pipe(finalize(() => this.spinner.hide()));
  }

  listTransactions(criteria: any): Observable<Pageable<Transaction>> {
    const params = {
      listByType: criteria.listByType,
      listByStatus: criteria.listByStatus,
      searchTerm: criteria.searchTerm,
      page: criteria.page.toString(),
      totalPages: criteria.totalPages
    };
    if (params.listByStatus === TransactionStatus.ALL) {
      delete params.listByStatus;
    }
    return this.http.get<Pageable<Transaction>>(TRANSACTION_URL, { params });
  }

  patchWithdrawTransactionById(transactionId: string): Observable<any> {
    this.spinner.show();
    return this.http.patch(`${TRANSACTION_URL}/${transactionId}`, { markAsPaid: true })
      .pipe(finalize(() => this.spinner.hide()));
  }

  postRefundTransaction(transactionId: string, refundStatus: any): Observable<any> {
    this.spinner.show();
    return this.http.post(`${TRANSACTION_URL}/${transactionId}/refund`, refundStatus)
      .pipe(finalize(() => this.spinner.hide()));
  }

  getCashoutReceipt(pixId: string): Observable<Blob> {
    this.spinner.show();
  
    return this.http.get(`${CLUB_PAYMENT_CASH_OUT_URL}/${pixId}/receipt`, {
        headers: { 'Content-Type': 'application/pdf' },
        responseType: 'blob'
      })
      .pipe(
        catchError((error) => { 
          console.error("Failed to fetch receipt", error);
          this.notificationService.showError('Erro ao gerar comprovante. Tente novamente', 'Comprovante não gerado.')
          return throwError(error);
        }),
        finalize(() => this.spinner.hide())
      );
  }
}
